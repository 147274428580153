<template>
  <article>
    <!-- Início Principal -->
    <v-toolbar flat>
      <v-icon>fas fa-tag</v-icon>
      <v-toolbar-title class="pl-4"> Objetos Aguardando Envio </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fechar">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <Data :title="$route.name" :dataInicio="camposDatas.dataInicial" :dataFinal="camposDatas.dataFinal" :indicadores="indicadores" />
    <!-- Fim Principal -->
  </article>
</template>

<script>
import moment from 'moment';
import Template from '../../components/Template.vue';
import Data from './frmData.vue';

export default {
  name: 'AguardandoEnvio',
  components: {
    Template,
    Data
  },

  data: () => ({
    indicadores: false,
    camposDatas: {
      dataInicial: moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'),
      dataFinal: moment(new Date()).format('YYYY-MM-DD'),
    }
  }),

  methods: {
    fechar() {
      this.$router.push("/");
    },    

    routeLoaded(){
      let data_inicio = this.$route.params.data_inicio;
      let data_fim = this.$route.params.data_fim;
      this.indicadores = true;
      if(data_inicio == '' && data_fim == ''){
        data_inicio = moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD');
        data_fim = moment(new Date()).format('YYYY-MM-DD');
      }
      this.camposDatas.dataInicial = data_inicio
      this.camposDatas.dataFinal = data_fim;
    }
  },

  async created(){
    if(this.$route.params.data_fim != null)
      this.routeLoaded();
  },

  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
  }
}
</script>